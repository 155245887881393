const BASE_URI = "http://localhost:8000";

async function get(endpoint) {
  const response = await fetch(`${BASE_URI}${endpoint}`, {
    method: "GET",
    headers: baseHeaders(),
  });
  return response.json();
}
async function post(endpoint, data) {
  const response = await fetch(`${BASE_URI}${endpoint}`, {
    method: "POST",
    headers: baseHeaders(),
    body: JSON.stringify(data),
  });
  return response.json();
}
async function put(endpoint, data) {
  const response = await fetch(`${BASE_URI}${endpoint}`, {
    method: "PUT",
    headers: baseHeaders(),
    body: JSON.stringify(data),
  });
  return response.json();
}
function baseHeaders() {
  return {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };
}

const APIService = {
  get,
  post,
  put,
};

export default APIService;
