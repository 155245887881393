import logo from "../assets/app-logo.png";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Modal,
  Typography,
  Input,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import APIService from "../services/api";
import { collectionCache } from "../services/nft";

export default function Home() {
  const navigate = useNavigate();
  const [isLoginModal, setLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");

  function handleLoginClose() {
    setLoginModal(false);
  }
  function handleSubmit(e) {
    e.preventDefault();
    localStorage.setItem("username", username);
    APIService.put("/me", {
      username,
    });
    navigate("/profile");
  }

  async function login() {
    if (!window.RGBConnect) return;
    setLoginModal(true);
    setIsLoggedIn(false);
    const { token } = await window.RGBConnect.login("ws://localhost:8100");
    localStorage.setItem("token", token);
    collectionCache.value = null;
    const { user } = await APIService.get("/me");
    if (user?.username) {
      localStorage.setItem("username", user.username);
      navigate("/profile");
    }
    setIsLoggedIn(true);
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div className="App-header">
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Earn <strong>RGB NFTs</strong> by playing the Monty Hall game!
      </p>
      <p>
        <Button variant="outlined" onClick={login}>
          Log in with RGB Wallet
        </Button>
      </p>
      <Modal
        open={isLoginModal}
        onClose={handleLoginClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleLoginClose}
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          {isLoggedIn ? (
            <>
              <Typography variant="h6" component="h2">
                Successfully logged in!
              </Typography>
              <Box sx={{ mt: 2 }}>
                <form onSubmit={(e) => handleSubmit(e)}>
                  Choose a username to proceed:
                  <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                    <Input
                      placeholder="Username"
                      value={username}
                      required
                      onChange={(e) => setUsername(e.target.value)}
                    />
                    <Button type="submit" variant="outlined">
                      Ok
                    </Button>
                  </Box>
                </form>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h6" component="h2">
                Logging in...
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
}
