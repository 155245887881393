import { useState } from "react";
import "./Game.css";
import doorImg from "../assets/door.png";
import doorBg from "../assets/door-bg.png";
import goat from "../assets/goat.png";
import cx from "classnames";
import { Box, Button } from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import { AVAILABLE_NFTS, collectionCache } from "../services/nft";
import APIService from "../services/api";

export default function Game() {
  function generateDoors() {
    const doors = [
      {
        prize: "goat",
        isOpen: false,
        isSelected: false,
        media: goat,
      },
      {
        prize: "goat",
        isOpen: false,
        isSelected: false,
        media: goat,
      },
    ];
    const carPrizes = Object.entries(AVAILABLE_NFTS).map(([id, nft]) => ({
      id,
      media: nft.media,
      name: nft.name,
    }));
    const carIndex = Math.floor(Math.random() * 3);
    const carPrize = carPrizes[carIndex];
    const winningDoor = {
      prize: "car",
      isOpen: false,
      isSelected: false,
      assetId: carPrize.id,
      media: carPrize.media,
      name: carPrize.name,
    };

    const winningDoorIndex = Math.floor(Math.random() * 3);
    doors.splice(winningDoorIndex, 0, winningDoor);
    for (let i = 0; i < doors.length; i++) doors[i].index = i;
    return doors;
  }
  const [doors, setDoors] = useState(() => generateDoors());
  const [selectedDoor, setSelectedDoor] = useState(null);
  const [instructions, setInstructions] = useState("Select a door to begin");
  const [step, setStep] = useState("select");

  function updateDoor(index, data) {
    setDoors((doors) =>
      doors.map((door, i) => {
        if (i === index) {
          return { ...door, ...data };
        }
        return door;
      })
    );
  }

  function selectDoor({ index }) {
    switch (step) {
      case "select":
        updateDoor(index, { isSelected: true });
        setSelectedDoor(index);
        setInstructions(
          `You picked <strong>door ${
            index + 1
          }</strong>. I will now reveal a goat behind one of the other doors...`
        );
        setStep("reveal");
        setTimeout(() => revealGoat(index), 1500);
        break;
      case "switch":
        if (doors[index].isOpen) return;
        updateDoor(index, { isSelected: true });
        setInstructions(
          selectedDoor === index
            ? "You decided to stick with your original choice.<br />Let's see if your 1<sup>st</sup> intuition was right..."
            : "You decided to switch doors.<br />Let's see if this strategy pays off..."
        );
        setStep("reveal");
        setTimeout(() => revealPrize(index), 1500);
        break;
      default:
        break;
    }
  }

  function revealGoat(selectedIndex) {
    const eligibleDoors = doors.filter(
      (door) => door.prize === "goat" && door.index !== selectedIndex
    );
    const goatIndex = Math.floor(Math.random() * eligibleDoors.length);
    const doorIndex = eligibleDoors[goatIndex].index;
    updateDoor(doorIndex, { isOpen: true });
    setTimeout(() => {
      updateDoor(selectedIndex, { isSelected: false });
      setInstructions(
        `I have revealed a goat behind <strong>door ${
          doorIndex + 1
        }</strong>.<br />Would you like to switch doors or stick with door ${
          selectedIndex + 1
        }?`
      );
      setStep("switch");
    }, 1000);
  }

  function revealPrize(selectedIndex) {
    const winningDoor = doors.find((door) => door.prize === "car");
    const remainingDoor = doors.find(
      (door) => door.index !== selectedIndex && !door.isOpen
    );
    updateDoor(selectedIndex, { isOpen: true });
    setTimeout(() => {
      updateDoor(remainingDoor.index, { isOpen: true });
    }, 400);
    setTimeout(() => {
      if (selectedIndex === winningDoor.index) {
        setInstructions(
          "Congratulations! You found the car!!<br />You're now the proud owner of a <strong>" +
            winningDoor.name +
            "</strong>!"
        );
        claimReward(winningDoor).catch(console.error);
      } else {
        setInstructions(
          `Unfortunately, the car was behind <strong>door ${
            winningDoor.index + 1
          }</strong>...<br />Better luck next time!`
        );
      }
      setTimeout(() => {
        setStep("end");
      }, 1000);
    }, 1000);
  }

  async function claimReward(winningDoor) {
    if (collectionCache.value) {
      const exisingAsset = collectionCache.value.find(
        (nft) => nft.id === winningDoor.assetId
      );
      if (exisingAsset) {
        exisingAsset.amount++;
      } else {
        collectionCache.value.push({
          id: winningDoor.assetId,
          amount: 1,
          name: winningDoor.name,
        });
      }
    }
    APIService.post("/claim", {
      assetId: winningDoor.assetId,
    }).catch(console.error);
  }

  function resetGame() {
    if (step !== "end") return;
    setStep("reset");
    setDoors(generateDoors());
    setSelectedDoor(null);
    setTimeout(() => {
      setInstructions("Select a door to begin");
      setStep("select");
    }, 1000);
  }

  return (
    <div className="App-body Game">
      <h1>Monty RGB</h1>
      <div className="instructions">
        <div dangerouslySetInnerHTML={{ __html: instructions }} />
      </div>
      <div className="doors">
        {doors.map((door) => (
          <div
            className={cx("door", `door-${door.prize}`, {
              selected: door.isSelected,
              open: door.isOpen,
            })}
            key={door.index}
            onClick={() => selectDoor(door)}
          >
            <img className="door-bg" src={doorBg} alt="Door" />
            <img className="door-prize" src={door.media} alt={door.prize} />
            <img className="door-fg" src={doorImg} alt="Door" />
          </div>
        ))}
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          mt: 2,
          visibility: step === "end" ? "visible" : "hidden",
        }}
      >
        <Button href="/profile">Back</Button>
        <Button
          href="/play"
          variant="contained"
          color="primary"
          onClick={resetGame}
        >
          <PlayCircleOutlineIcon />
          &nbsp;Play Again
        </Button>
      </Box>
    </div>
  );
}
