import {
  Box,
  Button,
  Card,
  CardHeader,
  CardMedia,
  CircularProgress,
} from "@mui/material";
import { useEffect, useMemo } from "react";
import "./Profile.css";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { AVAILABLE_NFTS, collectionCache } from "../services/nft";

export default function Profile() {
  const username = useMemo(() => localStorage.getItem("username"), []);

  const { data: nftCollection, refetch } = useFetch("GET", "/collection");
  const nftAssets = useMemo(() => {
    if (!nftCollection?.assets) return;
    const res = nftCollection.assets
      .filter((asset) => AVAILABLE_NFTS[asset.id])
      .map((asset) => ({
        ...asset,
        confirmedAmount: asset.amount,
        media: AVAILABLE_NFTS[asset.id].media,
      }));
    if (collectionCache.value) {
      for (const asset of collectionCache.value) {
        const exisingAsset = res.find((nft) => nft.id === asset.id);
        if (exisingAsset) {
          if (exisingAsset.amount !== asset.amount) {
            exisingAsset.confirmedAmount = exisingAsset.amount;
            exisingAsset.amount = asset.amount;
            exisingAsset.pending = true;
          }
        } else {
          res.push({
            ...asset,
            confirmedAmount: 0,
            media: AVAILABLE_NFTS[asset.id].media,
            pending: true,
          });
        }
      }
    }
    res.sort((a, b) => a.id.localeCompare(b.id));
    collectionCache.value = res;
    if (!res.length) return;
    return res;
  }, [nftCollection]);

  const isPendingNft = useMemo(() => {
    if (!nftAssets) return false;
    return nftAssets.some((nft) => nft.pending);
  }, [nftAssets]);

  useEffect(() => {
    if (isPendingNft) {
      const interval = setInterval(() => {
        refetch();
      }, 3000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPendingNft]);

  const navigate = useNavigate();
  function logout() {
    localStorage.removeItem("username");
    collectionCache.value = null;
    navigate("/");
  }

  return (
    <div className="App-body">
      <h1>
        Welcome, <strong>{username}</strong>
      </h1>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
        <Button href="/play" variant="contained" color="primary">
          <PlayCircleOutlineIcon />
          &nbsp;Play Monty RGB
        </Button>
        <Button onClick={logout} variant="outlined">
          <ExitToAppIcon />
          &nbsp;Log out
        </Button>
      </Box>
      <h2>Your current collection</h2>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 1,
          mb: 2,
          flexWrap: "wrap",
        }}
      >
        {nftAssets ? (
          nftAssets.map((nft) => (
            <Card
              key={nft.id}
              sx={{
                width: 205,
                paddingX: 1,
                paddingBottom: 2,
                position: "relative",
              }}
            >
              {nft.pending && (
                <div className="nftLoader">
                  <CircularProgress sx={{ color: "#e9ecf7" }} />
                </div>
              )}
              <CardHeader
                title={nft.name}
                subheader={`Amount: ${nft.confirmedAmount}`}
                sx={{ paddingBottom: 1 }}
              />
              <Box sx={{ marginX: 1 }}>
                <CardMedia
                  component="img"
                  width="120"
                  height="120"
                  image={nft.media}
                  alt={nft.name}
                  sx={{
                    backgroundColor: "#e9ecf7a0",
                    borderRadius: 3,
                  }}
                />
              </Box>
            </Card>
          ))
        ) : nftCollection ? (
          <div className="emptyCollection">
            Your collection is empty. Play Monty RGB to start earning NFTs!
          </div>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </div>
  );
}
