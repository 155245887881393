import car1 from "../assets/car1.png";
import car2 from "../assets/car2.png";
import car3 from "../assets/car3.png";

export const AVAILABLE_NFTS = {
  "rgb:sLNaJXXk-p1Wo8Ao-tYs4ojY-djbR74P-Av53RUX-YuUwGBE": {
    name: "Shadowfire",
    media: car1,
  },
  "rgb:2XIwmbvr-moSAKoT-b6Uwh61-ZS6Gdcd-LN05QY$-fZ1fnLg": {
    name: "Urban Spear",
    media: car2,
  },
  "rgb:QshUjScn-EfZFrOO-hQa4TwT-8B4e!rI-kgZ$JwL-mZd8jzk": {
    name: "Classic Phoenix",
    media: car3,
  },
};

export const collectionCache = {
  value: null,
};
