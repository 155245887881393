import { useEffect, useRef, useState } from "react";
import APIService from "../services/api";

export function useFetch(method, endpoint, body) {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  async function fetchUrl() {
    setLoading(true);
    try {
      const res = await APIService[method.toLowerCase()](endpoint, body);
      setData(res);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  }

  const handler = useRef(null);
  useEffect(() => {
    handler.current = setTimeout(() => fetchUrl());
    return () => clearTimeout(handler.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method, endpoint]);

  function refetch() {
    clearTimeout(handler.current);
    return fetchUrl();
  }

  return { data, error, loading, refetch };
}
